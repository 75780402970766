import React from 'react'
import Skeleton from 'react-loading-skeleton'
const SkeletonLeaderBoardProfile = () => {
  const radius='3.5rem'
  const stuRank='3.5rem'
  const stuName='4.5rem'
  return (
    <>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    <div className='profileMainContainer'>
        <div className="profileInnerContainer">
        <div className="imageContainer">
            <Skeleton circle={true} height={radius} width={radius} />
        </div>
        <div className="stuRank"><Skeleton width={stuRank}/> </div>
        <div className="stuName"><Skeleton width={stuName}/></div>
      </div>
    </div>
    </>
  )
}

export default SkeletonLeaderBoardProfile