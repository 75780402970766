import React from 'react'
import './Register.css'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import BackArrow from '../../assets/images/backArrow.png'
import PowLogo from '../../assets/images/powLogo.png'
import { ActionButton, Logo } from '../../components';
import { useLoginContext } from '../../context/LoginDetailsContext';
import { postData } from '../../services/api';
import toast, { Toaster } from 'react-hot-toast';



const Register = () => {
  const [parentName, setParentName] = useState('')
  const navigate = useNavigate()
  // const [error, setError] = useState("");
  const { setParent} = useLoginContext()

  const updateParentName = async () => {
    const data = { "name": `${parentName}` }
    const updateParentApi = '/parent/'
    try {
      const res = await postData(updateParentApi, data)
    } catch (err) {
      toast.error(err.message);
    }
  }

  // Handle Continue Button
  const continueButton = () => {
    if (parentName.length > 0) {
      setParent(parentName)
      updateParentName()
      navigate('/link')
    }
    else {
      toast.error("Please enter a valid name");
    }
  }



  return (
    <div className='main'>
      <div className="topBar">
        <div className="backArrow" ><img src={`${BackArrow}`} alt="Back" onClick={() =>navigate(-1)} /></div>
        <Logo />
      </div>

      <div className="mainRegisterContainer">
        <div className="parentNameWrapper">
          <div className='parentNameTitle'>Please tell us your good name</div>
          <input type="text" name="parentName" id="parentName" placeholder='Guardian Name' onChange={(e) => { e.preventDefault(); setParentName(e.target.value) }} value={parentName} />

        </div>
        <div className="action-button-wrapper" style={{marginTop:'1em'}}>
          <ActionButton text='Save' isDisabled={false} handleOnClick={continueButton} />
        </div>
        <div><Toaster position="top-center" reverseOrder={true} /></div>
      </div>

      <div className="footer">
        <div className="powBy">Powered by</div>
        <div className="powLogo"><img src={`${PowLogo}`} alt="Back" /></div>
      </div>
      <div><Toaster position="top-center" reverseOrder={true} /></div>
    </div>
  )
}

export default Register

