import './LinkEntity.css'
import React, { useState } from 'react'
import BackArrow from '../../assets/images/backArrow.png'
import PowLogo from '../../assets/images/powLogo.png'
import ParentToStudent from '../../assets/images/parent_to_student.png'
import { NavLink } from 'react-router-dom'
import { Logo } from '../../components'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { postData } from '../../services/api'
import toast, { Toaster } from 'react-hot-toast';
import { useLinkStudent } from '../Home/useHomeDetail'
const LinkEntity = () => {
  const navigate = useNavigate()
  const [studentCode, setStudentCode] = useState('')
  const [selectedOption, setSelectedOption] = useState(null);
  const [addMore, setAddMore] = useState(false)
  const {linkStudent,errors }= useLinkStudent(studentCode,selectedOption,setAddMore)

  const options = [
    { value: 'father', label: 'Father' },
    { value: 'mother', label: 'Mother' },
    { value: 'guardian', label: 'Guardian' },
  ];
  const customStyles = {
    menu: base => ({
      ...base,
      marginTop: 0,

    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "rgb(66, 33, 66)" : "#fff",
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.1em',
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      border: "none",
      borderRadius: "8px",
      boxShadow: "none",
      textAlign: "left",
      fontSize: '1.2em',
      width: '100%',
    }),

  };


  

  const continueButton =async () => {
    console.log('line 74',errors);
    if (studentCode.length > 0 && selectedOption != null) {
      await linkStudent()
      errors.current&&toast.error(errors.current)
    }
    else {
      toast.error("Please Enter Student Code and Select Relation");
    }
  }
  const addMoreButton = () => {
    setAddMore(false)
    navigate('/link')
    setSelectedOption(null)
    setStudentCode('')
  }
  const returnHomeButton = () => {
    navigate('/')
  }
  return (
    <div className='main'>
      <div className="topBar">

        <div className="backArrow">
          <NavLink onClick={()=>navigate(-1)}><img src={`${BackArrow}`} alt="Back" /></NavLink>
        </div>
        <Logo />
      </div>
      <div className="mainContainer" style={{ justifyContent: 'space-evenly' }}>
        <div className="pageLogo">
          <img src={ParentToStudent} alt="Cannot Load Image" />
        </div>
        <div className="linkTitle">Link Student Account</div>
        <div className="linkCaption">Ask the Student to provide their code and enter it here so to access the Student's progress and analytics</div>
        <input type="text" name="studentCode" id="studentCode" placeholder='Enter Student Code' onChange={(e) => { e.preventDefault(); setStudentCode(e.target.value) }} value={studentCode} />

        <Select className='select'
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options} placeholder='Relation to Student' styles={customStyles}
          components={{ IndicatorSeparator: () => null }}
        />

        {!addMore && <button className="linkAcc" onClick={continueButton}>Link Account</button>}
        {addMore && <div className='addMore'>
          <button className="addMoreButton" onClick={addMoreButton} >Add More</button>
          <button className="returnHomeButton" onClick={returnHomeButton} >Proceed</button>
        </div>}
        <div><Toaster position="top-center" reverseOrder={true} /></div>
      </div>
      <div className="footer">
        <div className="powBy">Powered by</div>
        <div className="powLogo"><img src={`${PowLogo}`} alt="Back" /></div>
      </div>
    </div>
  )
}

export default LinkEntity