import './QuizCard.css'
import moment from 'moment/moment'
import React from 'react'
 
const QuizCard = ({ quizData }) => {

  return ( 
    <div className='quizCardContainer' >
      <div className="quizTime">{moment(quizData.startDate).format('h:mm a')}</div>
      <div className="quizName" >{quizData.name}</div>
    </div>
  )
}

export default QuizCard