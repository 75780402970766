import React from 'react'
import './QuizCard.css'
import Skeleton from 'react-loading-skeleton'
const SkeletonQuizCard = () => {
    const dateWidth = '7vh'
    const dateHeight = '2.5vh'
    const quizNameWidth = '30vh'
    const quizNameHeight = '2.5vh'
    return (
        <>
            <div className='quizCardContainer'>
                <div className="quizTime"><Skeleton width={dateWidth} height={dateHeight} /></div>
                <div className="quizName"><Skeleton width={quizNameWidth} height={quizNameHeight} /></div>
            </div>
            <div className='quizCardContainer'>
                <div className="quizTime"><Skeleton width={dateWidth} height={dateHeight} /></div>
                <div className="quizName"><Skeleton width={quizNameWidth} height={quizNameHeight} /></div>
            </div>
            <div className='quizCardContainer'>
                <div className="quizTime"><Skeleton width={dateWidth} height={dateHeight} /></div>
                <div className="quizName"><Skeleton width={quizNameWidth} height={quizNameHeight} /></div>
            </div>
            <div className='quizCardContainer'>
                <div className="quizTime"><Skeleton width={dateWidth} height={dateHeight} /></div>
                <div className="quizName"><Skeleton width={quizNameWidth} height={quizNameHeight} /></div>
            </div>
        </>
    )
}

export default SkeletonQuizCard