import React from 'react'
import './Stats.css'
import Skeleton from 'react-loading-skeleton'
const SkeltonStats = () => {
  const radius = '15vh'
  return (
    <div className='statsContainer'>
      <div className="graph">
        <Skeleton circle height={radius} width={radius} />
      </div>
      <div className="labels">
        <div className="correct"><Skeleton width='8rem' /></div>
        <div className="incorrect"><Skeleton width='8rem' /></div>
        <div className="na"><Skeleton width='8rem' /></div>
      </div>
    </div>
  )
}

export default SkeltonStats