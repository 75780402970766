import './index.css'
import React from 'react'
import logo from '../../../assets/images/logo.png'

const Logo = () => {
  return (
    <div className="navLogo"><img className="logo" src={`${logo}`} alt="Back" /></div>
  )
}

export default Logo