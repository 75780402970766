import './index.css'
import PowLogo from "../../assets/images/powLogo.png";


const PoweredByExampil = () => {
    return (
        <div className="footer-container">
            <div>Powered by</div>
            <div>
                <img src={PowLogo} alt="Exampil Logo" />
            </div>
        </div>
    )
}

export default PoweredByExampil