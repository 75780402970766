import { useEffect, useState, useRef } from 'react'

import { getData, postData } from '../../services/api'
const useGraphDetail = (selectedStudent) => {
    const errors = useRef(null)
    const [qBankUsage, setQBankUsage] = useState()
    const [performance, setPerformance] = useState()
    const [loadingGraph, setLoadingGraph] = useState(true)
    const graphDataFetch = async ({ id }) => {
        const statsApi = `/parent/student/${id}/home-page-graph`
        setLoadingGraph(true)
        try {
            const res = await getData(statsApi)
            const data = res.data[0]
            setQBankUsage(data.qBankUsage)
            setPerformance(data.performance)
            setLoadingGraph(false)
            errors.current = null
        }
        catch (err) {
            errors.current = err.message
        }
    }
    useEffect(() => {
        if (selectedStudent !== '') {
            graphDataFetch({ id: selectedStudent.id })
        }
    }, [selectedStudent])
    return { qBankUsage, performance, loadingGraph, errors }
}


const useLeaderBoard = () => {
    const errors = useRef(null)
    const [studentDetails, setStudentDetails] = useState()
    const [loadingLeaderBoard, setLoadingLeaderBoard] = useState(true)

    const leaderboardDataFetch = async () => {
        const leaderboardApi = '/leaderboard/home'
        try {
            const res = await getData(leaderboardApi)
            setStudentDetails(res.data.leaderboard.all)
            setLoadingLeaderBoard(false)
            errors.current = null

        }
        catch (err) {
            errors.current = err.message

        }
    }
    useEffect(() => {
        leaderboardDataFetch()
    }, [])

    return { studentDetails, loadingLeaderBoard, errors }
}



const useTodayUsage = (selectedStudent) => {
    const errors = useRef(null)
    const [todayQbankUsed, setTodayQbankUsed] = useState(0)
    const [todayFlashcardUsed, setTodayFlashcardUsed] = useState(0)
    const todayUsageData = async (selectedStudent) => {
        const todayUsageApi = `/parent/student/${selectedStudent}/today-usage`
        try {
            const res = await getData(todayUsageApi)
            setTodayQbankUsed(res.data[0].QBank.answerdCount)
            setTodayFlashcardUsed(res.data[0].flashcard.usedCount)
            errors.current = null

        }
        catch (err) {
            errors.current = err.message

        }
    }
    useEffect(() => {
        if (selectedStudent !== '') {
            todayUsageData(selectedStudent)
        }
    }, [selectedStudent])
    return { todayQbankUsed, todayFlashcardUsed, errors }
}

const useGetAllCourses = () => {
    const errors = useRef(null)
    const [allCourses, setAllCourses] = useState([])
    const [loadingAllCourses, setLoadingAllCourses] = useState(true)
    const getAllCourses = async () => {
        const courseApi = '/course'
        try {
            const response = await getData(courseApi)
            setAllCourses(response.data)
            setLoadingAllCourses(false)
            errors.current = null

        }
        catch (err) {
            errors.current = err.message

        }
    }
    useEffect(() => {
        getAllCourses()
    }, [])
    return { allCourses, loadingAllCourses, errors }
}

const useLinkedStudents = (setParentName, setStudents, setSelectedStudent) => {
    const errors = useRef(null)
    const [loadingLinkStudent, setLoadingLinkStudent] = useState(true)
    const [linkStudentLoading, setLinkStudentLoading] = useState(true)
    const getLinkedStudents = async () => {
        const linkedStudentsApi = '/parent/link-student'
        try {
            const res = await getData(linkedStudentsApi)
            setParentName(res.name.split(' ')[0][0].toUpperCase() + res.name.split(' ')[0].slice(1))
            setStudents(res.linkedStudents.map((student) => getStudentNameId(student)))
            const firstStudent = { name: res.linkedStudents[0].details.name, id: res.linkedStudents[0].details._id }
            setSelectedStudent(firstStudent)
            setLinkStudentLoading(false)
            setLoadingLinkStudent(false)
            errors.current = null
        }
        catch (err) {
            errors.current = err.message
            if(err.message==='Forbidden'){
                localStorage.clear()
                window.location.href = '/login'
            }
        }
    }
    const getStudentNameId = (student) => {
        return { name: student.details.name.split(' ')[0][0].toUpperCase() + student.details.name.split(' ')[0].slice(1), id: student.details._id }
    }
    useEffect(() => {
        getLinkedStudents()
    }, [])

    return { loadingLinkStudent, linkStudentLoading, errors }
}

const useLinkStudent = (studentCode, selectedOption, setAddMore) => {
    const errors = useRef(null)
    const linkStudent = async () => {
        const data = {
            "studentCode": `${studentCode}`,
            "relationship": `${selectedOption.value}`
        }
        const linkStudentApi = '/parent/link-student'
        try {
            const res = await postData(linkStudentApi, data)
            setAddMore(true)
            errors.current = null
        } catch (err) {
            setAddMore(false)
            errors.current = err.message
        }
    }
    console.log(errors);

    return { linkStudent, errors }
}
export { useGraphDetail, useLeaderBoard, useTodayUsage, useGetAllCourses, useLinkedStudents, useLinkStudent }