import './QBank.css'
import React from 'react'
import { BsFillCircleFill } from 'react-icons/bs'
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label } from 'recharts';

Chart.register(ArcElement);

const QBank = ({ qBankUsage }) => {
    const { usedCount, totalCount, unUsedCount } = qBankUsage
    let labelValue=(((usedCount)/(usedCount+unUsedCount))*100).toFixed(2)

    const data = [
      { name: 'Group A', value: usedCount },
      { name: 'Group B', value: unUsedCount }
    ];
    const COLORS = ['#8C3562', '#dbdbdb'];
    return (
        <div className='qBankMainContainer'>
            <div className="graph">
            <PieChart width={140} height={75} >
          <Pie
            data={data}
            cx='50%'
            cy='100%'
            innerRadius={50}
            outerRadius={65}
            fill="#8884d8"
            paddingAngle={2}
            cornerRadius={6}
            dataKey="value"
          startAngle={180}
          endAngle={0}
          >
            <Label
              value={labelValue+'%'} position="centerBottom" className='labelColor' fontSize='25px' fontWeight={600} fill='#8C3562'
            />
            
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
            </div>
            <div className="qLabel">
                <div className="totalQues">Total Questions</div>
                <div className="used"><BsFillCircleFill />Used ({usedCount})</div>
                <div className="unused"><BsFillCircleFill />Unused ({unUsedCount})</div>
            </div>
        </div>
    )

}

export default QBank