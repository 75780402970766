import './Date.css'
import React from 'react' 
import moment from 'moment/moment'
const DateDisplay = ({ date, onCLickQuizData,onClickColorDate }) => {
  
  return (
    <div className="dateMain">
      <div className='dateContainer' style={{backgroundColor:onClickColorDate===date?'#8c3962':'#FFFFFF', color:onClickColorDate===date?'#FFFFFF':'#000000'} }  onClick={() => { onCLickQuizData(date);  }}>
        <div className="numDate">{moment(date).format('D')}</div>
        <div className="alphaDate">{moment(date).format('dddd').slice(0, 3)}</div>
      </div>
    </div>
  )
} 

export default DateDisplay