import "./Calendar.css";
import React, { useEffect, useState } from "react";
import DateDisplay from "../Date/Date";
import QuizCard from "../QuizCard/QuizCard";
import moment from "moment/moment";
import EmptyQuizCard from "../QuizCard/EmptyQuizCard";
import SkeletonQuizCard from "../QuizCard/SkeletonQuizCard";
import { getData } from "../../services/api";
import toast, { Toaster } from "react-hot-toast";
const Calendar = () => {
  const [quizData, setQuizData] = useState();
  const date = moment().format("YYYY-MM-DD");
  const [onClickColorDate, setOnClickColorDate] = useState(date);
  //Quiz Data Fetch
  const calendarApiByStartDate = "/quiz/startDate/";
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const onCLickQuizData = async (date) => {
    setLoadingCalendar(true);
    const endDate = moment(date).add(1, "days").format("YYYY-MM-DD");
    const url = `${calendarApiByStartDate + date}/endDate/${endDate}`;
    try {
      const res = await getData(url);
      setOnClickColorDate(date);
      setQuizData(res.data);
      setLoadingCalendar(false);
    }
    catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    onCLickQuizData(date);
  }, []);

  return (
    <div className="calendarMainContainer">
      <div className="dates">
        <div className="datesScroll">
          {(() => {
            let dateArray = [];
            for (let i = 0; i <= 9; i++) {
              //Next 4 days push
              let date = moment().add(i, "days").format("YYYY-MM-DD");
              dateArray.push(
                <DateDisplay
                  key={i}
                  date={date}
                  onCLickQuizData={onCLickQuizData}
                  onClickColorDate={onClickColorDate}
                />
              );
            }
            return dateArray;
          })()}
        </div>
      </div>
      <div className="quiz">
        {!loadingCalendar && quizData ? quizData.length !== 0 ? quizData.map((quizData, id) => {
          return <QuizCard key={id} quizData={quizData} />
        }) : <EmptyQuizCard /> : <SkeletonQuizCard />}
      </div>
      <div><Toaster position="top-center" reverseOrder={true} /></div>
    </div>
  );
};

export default Calendar;
