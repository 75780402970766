
import Logo from '../../assets/images/physics.jpg'
import './SubjectIcon.css'
import { useNavigate } from 'react-router-dom'
const SubjectIcon = ({allSubjects,selectedSubject}) => {
const navigate = useNavigate()
  
  return ( 
    <div className="subjectIconContainer" onClick={()=>{navigate('/subject',{state:{allSubjects:allSubjects,selectedSubject:selectedSubject}})}}>
      <div className="subjectInnerContainer">
        <div className="subjectIcon">
          <img src={Logo} alt="subject icon" />
        </div>
        <div className="subjectName">
          <p>{allSubjects.filter((subject)=>subject.subjectName===selectedSubject.subjectName&&subject.name ===selectedSubject.name )[0].subjectName}</p>
        </div>
      </div>
    </div>

  )
}

export default SubjectIcon