import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonQBank = () => {
  const radius = '11vh'
  return (
    <div className='qBankMainContainer'>
      <div className="graph">
        <Skeleton circle height={radius} width={radius} />
      </div>
      <div className="qLabel">
        <div className="totalQues"><Skeleton width='8rem' /></div>
        <div className="used"><Skeleton width='8rem' /></div>
        <div className="unused"><Skeleton width='8rem' /></div>
      </div>
    </div>
  )
}

export default SkeletonQBank