
import axios from '../helper/axios'
import { TOKEN_OBJECT_KEY } from '../helper/constant';
import moment from 'moment/moment';


export async function loginUser(data) {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
    };

    let bodyContent = JSON.stringify(data);

    let reqOptions = {
        url: '/parent/auth/login-with-firebase',
        method: "POST",
        headers: headersList,
        data: bodyContent,
    };

    try {
        let response = await axios.request(reqOptions);
        if (response.status === 200) {
            localStorage.setItem(TOKEN_OBJECT_KEY, JSON.stringify(response.data.tokens))
            return response.data
        }
    } catch (err) {
        throw new Error({ isSuccess: false, message: err.response.data.message })
    }

}

export const logoutUser = async () => {
    let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
    };

    const tokenObject = JSON.parse(localStorage.getItem(TOKEN_OBJECT_KEY))
    const accessToken = tokenObject.access.token
    const refreshToken = tokenObject.refresh.token
    let bodyContent = JSON.stringify({ token: accessToken,refreshToken: refreshToken});
    let reqOptions = {
        url: '/parent/auth/logout',
        method: "POST",
        headers: headersList,
        data: bodyContent,
    };

    try {
        let response = await axios.request(reqOptions);
        
        localStorage.clear()
        window.location.href = '/login'
    } catch (err) {
        throw new Error(err.response.data.message)
    }
}

const refreshToken = async (current) => {
    const tokenObject = JSON.parse(localStorage.getItem(TOKEN_OBJECT_KEY))
    const refreshTokenExpiry = tokenObject.refresh.expires
    if (moment(current).isAfter(moment(refreshTokenExpiry))) {
        // Refresh token is invalid, send to Login
        window.location.href = '/login'
    } else {
        // Refresh token is valid, update the tokens
        const data = { refreshToken: tokenObject.refresh.token }
        let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
        };

        let bodyContent = JSON.stringify(data);

        let reqOptions = {
            url: '/parent/auth/refresh-tokens',
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        try {
            let response = await axios.request(reqOptions);
            if (response.status === 200) {
                localStorage.setItem(TOKEN_OBJECT_KEY, JSON.stringify(response.data))
                return response.data
            }
        } catch (err) {
            throw new Error( { isSuccess: false, message: err.response.data.message })
        }
        // refreshToken('/auth/refresh-tokens', 'POST', bodyContent, user.tokens.refresh.token)
    }
}

export const getValidToken = async () => {
    // Get current time
    const current = moment().toISOString()
    if (JSON.parse(localStorage.getItem(TOKEN_OBJECT_KEY))) {
        const tokenObject = JSON.parse(localStorage.getItem(TOKEN_OBJECT_KEY))
        const tokenExpiry = tokenObject.access.expires
        // check if token is valid
        if (moment(current).isAfter(moment(tokenExpiry))) {
            // Access token is invalid, check Refresh token
            // Refresh the token, return it
            const validToken = await refreshToken(current)
            return validToken
        } else {
            // Access token is valid, return token
            return tokenObject.access.token
        }
    }
}

