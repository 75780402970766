import { useState,useEffect } from "react";
import './Accordion.css'
import Stats from "../Stats/Stats";
import { IoIosArrowDropdown } from "react-icons/io";
import { AiOutlineLeftCircle } from "react-icons/ai";
import { useLoginContext } from "../../context/LoginDetailsContext";
import { getData } from "../../services/api";
import SkeltonStats from "../Stats/SkeltonStats";
const accordionStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 'fit-content',
    flexDirection: "column",
    boxSizing: "border-box",
    borderRadius: '8px'
};
const sectionStyle = {

}

const accordionTitleStyles = {
    alignItems: "center",
    display: "flex", 
    justifyContent: "space-between",

    width: "95%",
    height: 'fit-content',
    padding:'1em 0',
    fontSize: "1.1em",
    cursor: "pointer",
    boxSizing: "border-box",
};

const accordionContentStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "fit-content",
    boxSizing: "border-box",
    borderTop: '0.5px dashed black'
};


const AccordionSection = ({
    chapter,
    isActiveSection,
    activeIndex,
    setActiveIndex,
    sectionIndex,
    currentStudentId
}) => {
    const toggleSection = () => {
        const nextIndex = isActiveSection ? null : sectionIndex;
        setActiveIndex(nextIndex);
    };
    const [loadingChapterStats, setLoadingChapterStats] = useState(true)
    const getPerformance = async (currentStudentId) => {
        setLoadingChapterStats(true)
        const chapterWiseAnalysisApi = `/parent/student/${currentStudentId}/analytics/chapter/${chapter.id}`
        const res = await getData(chapterWiseAnalysisApi)
        setPerformance(res.data[0].performance)
        setLoadingChapterStats(false)
    }
    const [performance, setPerformance] = useState([])
    return (
        <div style={sectionStyle} className="accordionSection">
            <div style={accordionTitleStyles} onClick={(chapter) => {toggleSection(); getPerformance(currentStudentId); }}>
                <div className="accordionTitle">{chapter.name}</div>
                <div className="accordionIcon">{isActiveSection ? <IoIosArrowDropdown className="openButton" /> : <IoIosArrowDropdown className="closeButton" />}</div>
            </div>

            {isActiveSection && (
                <div className="accordionContent" style={accordionContentStyles}>{!loadingChapterStats?<Stats performance={performance} />:<SkeltonStats/>}</div>
            )}
        </div>
    );
};

const Accordion = ({ chapters,activeIndex,setActiveIndex }) => {
    const { currentStudent } = useLoginContext()
    return (
        <div style={accordionStyles} className="accordion">
            {chapters.map((chapter,index) => (
                <AccordionSection
                    chapter={chapter}
                    key={index}
                    isActiveSection={index === activeIndex}
                    setActiveIndex={setActiveIndex}
                    sectionIndex={index}
                    currentStudentId={currentStudent}
                    activeIndex={activeIndex}
                />
            ))}
        </div>
    );
};

export default Accordion;