import React from 'react';

import './index.css'

const ActionButton = ({ text, isDisabled, handleOnClick }) => {
    return (
        <div className='action-button-container'>
            <button className='action-button' disabled={isDisabled} onClick={handleOnClick}>
                {text}
            </button>
        </div>);
};

export default ActionButton;