import React, { useEffect, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import "./index.css";
import BackArrow from "../../assets/images/backArrow.png";

import { auth } from "../../firebase.config";
import { ActionButton, Logo, PoweredByExampilFooter } from "../../components";


import { useLoginContext } from "../../context/LoginDetailsContext";
import { loginUser } from "../../services/auth";
import toast, { Toaster } from "react-hot-toast";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpActive, setOtpActive] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { dispatch } = useLoginContext()

  const countryCode = "+91"
  // use Reducer

  // api call for getting data from firebase
  const getUserData = async () => {
    const data = { mobile: countryCode + phone }
    try {
      const response = await loginUser(data)
      response.parent.name ? navigate("/") : navigate("/register")
      dispatch({ type: "GET_LOGIN_DETAILS_REQUEST", payload: response })
    }
    catch (err) {
      dispatch({ type: "GET_LOGIN_DETAILS_FAILED" })
    }
  }

  //Recaptcha 
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    });
  };

  //Phone Field Handler
  const onChangePhoneField = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };

  //Otp Field Handler
  const onChangeOtpHandler = (e) => {
    e.preventDefault();
    setOtp(e.target.value);
  };

  //Firebase Phone Auth Request Handler
  const toggleOtp = () => {
    // e.preventDefault();
    if (phone.length === 10) {
      setOtpActive(true);
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, countryCode + phone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          // Error; SMS not sent
          if (error.code === "auth/too-many-requests") {
            toast.error("Too many attempt. Try again Later");
          }
        });
    }
    else {
      toast.error("Please enter a valid number");
    }
  };

  //Firebase Otp Auth Request Handler
  const verifyOtp = () => {
    // e.preventDefault()
    if (otp.length === 6) {
      const confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then(() => {
          // User signed in successfully.
          getUserData();

          //Navigate to register page
        })
        .catch((error) => {
          if (error.code === "auth/invalid-verification-code") {
            toast.error("Invalid Otp.");
          }
        });
    } else {
    
      toast.error("Enter Valid Otp");
    }
  };

  //Firebase Otp Resend Handler
  const resendOtp = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': (response) => {

        },
        'expired-callback': () => {
        }

      }, auth);
    }
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, countryCode + phone, appVerifier)
    .then((confirmationResult) => {
    // SMS sent. Prompt user to type the code from the message, then sign the
    window.confirmationResult  =  confirmationResult;
    toast.success("Otp Resent");

    }).catch((error) => {
    // Error; SMS not sent
      //Display Error Message
    });
    
  };



  return (
    <div className="main">
      <div className="topBar">
        <div
          className="backArrow"
          style={
            !otpActive ? { visibility: "hidden" } : { visibility: "visible" }
          }
        >
          <img
            src={BackArrow}
            alt="Back Arrow Icon"
            onClick={() => {
              setOtpActive(false);
            }}
          />
        </div>
        <Logo />
      </div>

      <div className="mainContainer">
        <div className="loginTitle">Guardian's Login</div>

        {!otpActive ? (
          <>
            <div className="phoneNumber">
              
              <input className="countryCode" value={'+91'} disabled></input>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="9876543210"
                onChange={onChangePhoneField}
                value={phone}
              />
            </div>
            <div className="otpReq">Otp Verification Required</div>
            
            <div className="action-button-wrapper">
              <ActionButton text='Send OTP' isDisabled={false} handleOnClick={toggleOtp} />
            </div>
          </>
        ) : (
          <>
            <input
              type="text"
              name="otp"
              id="otp"
              placeholder="Enter your OTP Here"
              onChange={onChangeOtpHandler}
              value={otp}
              style={{ textAlign: "center" }}
            />
            <div className="otpReq" onClick={resendOtp}>Resend OTP</div>

            <div className="action-button-wrapper">
              <ActionButton text='Verify OTP' isDisabled={false} handleOnClick={verifyOtp} />
            </div>
          </>
        )}
        {/* <div className="error">
          <p style={{ color: "red" }}>{error}</p>
        </div> */}
        <div><Toaster  position="top-center" reverseOrder={true}/></div>
        <div id="sign-in-button" style={{display:'none'}}></div>
      </div>
      <PoweredByExampilFooter />
    </div>
  );
};

export default Login;



