import './EditProfile.css'
import React from 'react'
import { MdDeleteForever } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import Edit from '../../assets/images/Edit.png'
const SkeltonEditProfile = () => {
    const inputWidth='42vh',inputHeight='6vh',borderRadius='8px'
  return (

    <>
    <div className='editMain'>
      <div className='profileDetails'>
        <div className='nameCode' >
          <div className="profHeader"><Skeleton width={150}/></div>
          <label htmlFor="stuCode">Student Code</label>
        </div>
        <div className='deleteIcon' ><MdDeleteForever className='deleteStudent' /></div>
      </div>
      <div className="inputs">
        <Skeleton width={inputWidth} height={inputHeight} borderRadius={borderRadius}/>
        {/* <input type="text" name="stuCode" id="stuCode" /> */}
      </div>

      <label htmlFor="stuRel">Relationship with you</label>
      <div className="inputs">
        <Skeleton width={inputWidth} height={inputHeight} borderRadius={borderRadius}/>
      </div>
    </div>
    <div className='editMain'>
      <div className='profileDetails'>
        <div className='nameCode' >
          <div className="profHeader"><Skeleton width={150}/></div>
          <label htmlFor="stuCode">Student Code</label>
        </div>
        <div className='deleteIcon' ><MdDeleteForever className='deleteStudent' /></div>
      </div>
      <div className="inputs">
        <Skeleton width={inputWidth} height={inputHeight} borderRadius={borderRadius}/>
        {/* <input type="text" name="stuCode" id="stuCode" /> */}
      </div>

      <label htmlFor="stuRel">Relationship with you</label>
      <div className="inputs">
        <Skeleton width={inputWidth} height={inputHeight} borderRadius={borderRadius}/>
      </div>
    </div>
    </>
  )
}

export default SkeltonEditProfile