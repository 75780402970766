import React from 'react'
import { Navigate } from 'react-router-dom'
import { TOKEN_OBJECT_KEY } from '../helper/constant';
const ProtectedRoute = ({children}) => {
    const token = JSON.parse(localStorage.getItem(TOKEN_OBJECT_KEY))

    if(token===null) {
        return <Navigate to="/login" />
    }
 return children
}

export default ProtectedRoute