import './Student.css'
import React, { useEffect, useState } from 'react'
import Edit from '../../assets/images/edit1.png'
import Logo from '../../assets/images/officialLogo.png'
import FooterLogo from '../../assets/images/powLogo.png'
import Logout from '../../assets/images/logout.png'
import StudentMenu from '../../components/StudentMenu/StudentMenu'
import { getParentDetails } from '../../helper/profileData'
import { useNavigate } from 'react-router-dom'
import SkeletonStudentMenu from '../../components/StudentMenu/SkeletonStudentMenu'
import { logoutUser } from '../../services/auth'
import BackArrow from '../../assets/images/backArrow.png'
import PopupModal from '../../components/PopupModal/PopupModal'
import toast, { Toaster } from 'react-hot-toast'
const Student = () => {
    const [parentName, setParentName] = useState()
    const [linkedStudents, setLinkedStudents] = useState([])
    const [loading, setLoading] = useState(true)
    const [parentMobile, setParentMobile] = useState()
    const getParentDetail = async () => {
        try {
            const res = await getParentDetails()
            setParentName(res.name.split(' ')[0].split(' ')[0][0].toUpperCase() + res.name.split(' ')[0].slice(1))
            setLinkedStudents(res.linkedStudents)
            setLoading(false)
            setParentMobile(res.mobile)

        }
        catch (err) {
            toast.error(err.message);
        }
    }

    const logout = async () => {
        try {
            const res = await logoutUser()
        }
        catch (err) {
            toast.error(err.message);
        }
    }

    const navigate = useNavigate();

    useEffect(() => {
        getParentDetail()
    }, [])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const message = 'Logout'
    return (
        <div className='studentMain'>
            <PopupModal open={open} handleClose={handleClose} message={message} confirmButton={logout} />
                <div className="topBarStudent" style={{width:'90%'}}>
                    <div className="backArrowButton" ><img src={`${BackArrow}`} alt="Back" onClick={() => { navigate('/') }} /></div>
                    <div className="det">
                        {/* <div className="dpHolder" >
                            <img src={Logo} alt="" />
                        </div> */}
                        <div className="profInfo">
                            <div className="profName">{parentName}</div>
                            <div className="profContact">{parentMobile}</div>
                        </div>
                    </div>
                    <div className="editIcon" onClick={() => { navigate('/profile') }}>
                        <img src={Edit} alt="Edit" />
                    </div>
                </div>
                <div className="linkedStudentsCard" >
                    <div className="studentScroll">
                        {!loading ? linkedStudents.map((student, index) => {
                            return <StudentMenu key={index} {...student} index={index} />
                        }) : <SkeletonStudentMenu />}
                    </div>
                </div>
                <div className="studentFooter">
                    <div className="logout">
                        <div className="logoutLogo">
                            <img src={Logout} alt="" />
                        </div>
                        <div className="logoutText" onClick={handleOpen}>Logout</div>
                    </div>
                    <div className="footerLogo">
                        <img src={FooterLogo} alt="" />
                    </div>
                </div>
            <div><Toaster position="top-center" reverseOrder={true} /></div>
        </div>
    )
}

export default Student