import './Profile.css'
import React, { useEffect, useState } from 'react'
import DP from '../../assets/images/officialLogo.png'
import BackArrow from '../../assets/images/backArrow.png'
import Edit from '../../assets/images/Edit.png'
import EditProfile from '../../components/EditProfile/EditProfile'
import Add from '../../assets/images/add.png'
import { deleteData, postData } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import SkeltonEditProfile from '../../components/EditProfile/SkeltonEditProfile'
import Skeleton from 'react-loading-skeleton'
import { getParentDetails } from '../../helper/profileData'
import PopupModal from '../../components/PopupModal/PopupModal'
import toast,{Toaster} from 'react-hot-toast'
import { FaUserCircle } from 'react-icons/fa'

const Profile = () => {
    const navigate = useNavigate();
    const [nameDisabled, setNameDisabled] = useState(true)
    const [parentName, setParentName] = useState('')
    const [parentDetail, setParentDetail] = useState()
    const [linkedStudents, setLinkedStudents] = useState([])
    const [loading, setLoading] = useState(true)
    const [deleteStudentCode, setDeleteStudentCode] = useState()
    const [deleteRelation, setDeleteRelation] = useState()
    const saveProfile = () => {
        !nameDisabled && updateParentName(parentName)
    }
    const updateParentName = async (parentName) => {
        const data = { name: parentName }
        const updateParentApi = '/parent'
        try {
            const res = await postData(updateParentApi, data)
        } catch (err) {
            toast.error(err.message);
        }
    }


    const getParentDetail = async () => {
        try{
            const res = await getParentDetails()
            setParentName(res.name)
            setParentDetail(res)
            setLinkedStudents(res.linkedStudents)
            setLoading(false)
        }
        catch(err){
            toast.error(err.message);
        }
    }
    const linkMoreStudents = () => {
        navigate('/link')
    }
    const deleteStudentConfirm = (studentCode, relationship) => {
        handleOpen()
        setDeleteStudentCode(studentCode)
        setDeleteRelation(relationship)
    }
    const deleteStudent = async () => {
        const deleteStudentApi = '/parent/link-student'
        const data = {
            "studentCode": `${deleteStudentCode}`,
            "relationship": `${deleteRelation}`
        }

        try {
            const res = await deleteData(deleteStudentApi, data)
            setLinkedStudents(res.linkedStudents)
        } catch (err) {
            toast.error(err.message);
        }
    }

    useEffect(() => {
        getParentDetail()
    }, [])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const message = 'Delete student'
    return (
        <>
            <PopupModal open={open} handleClose={handleClose} confirmButton={deleteStudent} message={message} />
            <div className='profMainContainer'>
                <div className="nav">
                    <div className="backOption" onClick={() => { navigate('/student') }}><img src={BackArrow} alt="Back" />Edit Profile</div>
                    <div className="saveOption" onClick={saveProfile} >Save</div>
                </div>
                <div className="profInnerContainer">
                    <div className="mainProf">
                        <div className="mainDP">
                            <div className="imgHolder" >
                            {/* {parentDetail.profileImg?<img src={parentDetail.profileImg} alt="" style={{maxWidth:'100%',borderRadius:'50%'}}/>:<FaUserCircle size='fit-content' color='#DBDBDB'/>} */}
                            <FaUserCircle size='fit-content' color='#DBDBDB'/>
                            </div>
                        </div>
                        <div className="editName">
                            <label htmlFor="fullName">Full Name</label>
                            <div className="inputs">
                                {!loading ? <>
                                    <input type="text" name="fullName" id="fullName" onChange={(e) => { setParentName(e.target.value) }} value={parentName} disabled={nameDisabled} />
                                    <button onClick={() => { setNameDisabled(false) }}><img src={Edit} alt="Edit" /></button>
                                </> : <Skeleton width='42vh' height='6vh' borderRadius='8px' />}
                            </div>
                        </div>
                    </div>
                    <div className="otherDetails">
                        <div className="detailHolder">
                            {!loading ? linkedStudents.map((student, id) => {
                                return <EditProfile key={id} student={student} deleteStudent={deleteStudent} deleteStudentConfirm={deleteStudentConfirm} setLinkedStudents={setLinkedStudents}/>
                            }) : <SkeltonEditProfile />}
                            <div className="addStudentBtn">
                                <button onClick={linkMoreStudents}><img src={Add} alt="Add" /><div>Link more students</div></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div><Toaster position="top-center" reverseOrder={true} /></div>
            </div>
        </>
    )
}

export default Profile