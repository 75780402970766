import './Today.css'
import moment from 'moment/moment'
import React from 'react'
const Today = ({todayQbankUsed,todayFlashcardUsed}) => {
  const todayDate = moment().format("D MMM"); 
  const currentTimeInHours = moment().format("h a")

  return (
    <div className='todayMainContainer'>
      <div className="todayInnerHolder">
        <div className="singleTask">
          <div className="taskTitle">{todayQbankUsed} Questions Solved</div>
          <div className="taskDate">{todayDate}, 6 am-{currentTimeInHours}</div>
        </div>
        <div className="singleTask">
          <div className="taskTitle">{todayFlashcardUsed} Flash Card Revised</div>
          <div className="taskDate">{todayDate}, 6 am-{currentTimeInHours}</div>
        </div>
      </div>
    </div>
  )
}

export default Today