import './EditProfile.css'
import React, { useState } from 'react'
import Select from 'react-select';
import { MdDeleteForever } from 'react-icons/md' 
import { postData } from '../../services/api';
import toast, { Toaster } from "react-hot-toast";

const EditProfile = ({ student, deleteStudent,deleteStudentConfirm,setLinkedStudents }) => {
  const { studentCode, relationship, details } = student
  const [selectedOption, setSelectedOption] = useState({ value: relationship, label: relationship[0].toUpperCase() + relationship.slice(1) });
  const options = [
    { value: 'father', label: 'Father' },
    { value: 'mother', label: 'Mother' },
    { value: 'guardian', label: 'Guardian' },
  ];
  const customStyles = {
    menu: base => ({
      ...base,
      marginTop: 0,

    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "rgb(66, 33, 66)" : "#fff",
      height: '40px',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      fontSize: '1.1em',
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      border: "none",
      borderRadius: "8px", 
      boxShadow: "none",
      textAlign: "left",
      fontSize: '1em',
      width: '100%',
    }),
    // singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#111" }),
  };
  const updateStudentRelation = async (studentCode, relationship) => {
    const data = {
      studentCode,
      relationship
    }
    const updateParentApi = '/parent/update-student-relation'
    try {
      const res = await postData(updateParentApi, data)
      setLinkedStudents(res.linkedStudents)
    } catch (err) {
      toast.error(err.message);
    }
  }

  const [relDisabled, setRelDisabled] = useState(true)
  return (
    <>
 
    <div className='editMain'>
      <div className='profileDetails'>
        <div className='nameCode' >
          <div className="profHeader">{details.name}</div>
          <label htmlFor="stuCode">Student Code</label>
        </div>
        <div className='deleteIcon' onClick={() => { deleteStudentConfirm(studentCode, relationship) }}><MdDeleteForever className='deleteStudent' /></div>
      </div>
      <div className="inputs">
        <input type="text" name="stuCode" id="stuCode" value={studentCode} disabled />
        {/* <button onClick={() => { setCodeDisabled(false) }}><img src={Edit} alt="Edit" /></button> */}
      </div>

      <label htmlFor="stuRel">Relationship with you</label>
      <div className="inputs">
        <Select className='selectProfile'
          value={selectedOption}
          // defaultValue={selectedOption}
          onChange={(val) => {updateStudentRelation(studentCode, val.value);setSelectedOption(val)}}
          options={options} styles={customStyles}
          components={{ IndicatorSeparator: () => null }}
        />
      </div>
      <div><Toaster  position="top-center" reverseOrder={true}/></div>
    </div>
    </>
  )
}

export default EditProfile