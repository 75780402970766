import React from 'react'
import Logo from '../../assets/images/physics.jpg'
import Skeleton from 'react-loading-skeleton'
const SkeltonSubjectIcon = () => {
    const subjectNameWidth='4.5em', subjectIconRadius='4.5em'
    return (
        <>
            <div className="subjectIconContainer">
                <div className="subjectInnerContainer">
                    <div className="subjectIcon">
                        <Skeleton circle width={subjectIconRadius} height={subjectIconRadius}/>
                    </div>
                    <div className="subjectName">
                        <p><Skeleton width={subjectNameWidth}/></p>
                    </div>
                </div>
            </div>
            <div className="subjectIconContainer">
                <div className="subjectInnerContainer">
                    <div className="subjectIcon">
                        <Skeleton circle width={subjectIconRadius} height={subjectIconRadius}/>
                    </div>
                    <div className="subjectName">
                        <p><Skeleton width={subjectNameWidth}/></p>
                    </div>
                </div>
            </div>
            <div className="subjectIconContainer">
                <div className="subjectInnerContainer">
                    <div className="subjectIcon">
                        <Skeleton circle width={subjectIconRadius} height={subjectIconRadius}/>
                    </div>
                    <div className="subjectName">
                        <p><Skeleton width={subjectNameWidth}/></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SkeltonSubjectIcon