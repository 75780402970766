const LoginReducer = (state, action) => {
    switch (action.type) {
        case "GET_LOGIN_DETAILS_REQUEST":
            return { ...state, id: action.payload.parent.id, tokens: action.payload.tokens, mobile: action.payload.parent.mobile, parentName: action.payload.parent.name }
        case "SET_PARENT_NAME":
            return { ...state, parentName: action.payload}
        case "SET_SELECTED_STUDENT":
            return { ...state, currentStudent: action.payload}
        case "SET_TOKEN":

            return { ...state, tokens: action.payload }
        default:
            return { ...state }
    }
}

export default LoginReducer;