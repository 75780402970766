import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './StudentToggle.css'
const SkeletonStudentToggle = () => {
    return ( 
        <>
            <div className='toggelMainConatiner' style={{ borderBottom:'3px solid #883F69',width:'50%'}}>
                <div className="toggelText" ><Skeleton width='120px'  height='30px' /></div>
            </div>
            <div className='toggelMainConatiner' style={{ backgroundColor: '#F1F1F1', color: 'black',width:'50%' }}>
                <div className="toggelText" ><Skeleton width='120px' height='30px' /></div>
            </div>
        </>
    )
}

export default SkeletonStudentToggle