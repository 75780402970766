import React from 'react'
import './EmptyQuizCard.css'
const EmptyQuizCard = () => {
  return (
    <div className='emptyQuizCardContainer'>
      <h3>No Quiz for Today</h3>
    </div>
  )
}

export default EmptyQuizCard