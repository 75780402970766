import './Home.css'
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from "react-hot-toast";
import Logo from '../../assets/images/officialLogo.png'
import Stats from '../../components/Stats/Stats'
import QBank from '../../components/QBank/QBank'
import Today from '../../components/Today/Today'
import Calendar from '../../components/Calendar/Calendar'
import LeaderBoardProfile from '../../components/LeaderBoardProfile/LeaderBoardProfile'
import Notification from '../../components/Notification/Notification'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonLeaderBoardProfile from '../../components/LeaderBoardProfile/SkeletonLeaderBoardProfile'
import SkeltonStats from '../../components/Stats/SkeltonStats'
import SkeletonQBank from '../../components/QBank/SkeletonQBank'
import { useNavigate } from 'react-router-dom'
import StudentToggle from '../../components/StudentToggle/StudentToggle'
import SubjectIcon from '../../components/SubjectIcon/SubjectIcon'
import SkeletonStudentToggle from '../../components/StudentToggle/SkeletonStudentToggle'
import { useLoginContext } from '../../context/LoginDetailsContext';
import SkeltonSubjectIcon from '../../components/SubjectIcon/SkeltonSubjectIcon';
import { useGetAllCourses, useGraphDetail, useLeaderBoard, useLinkedStudents, useTodayUsage } from './useHomeDetail';

const Home = () => {
    const navigate = useNavigate()
    const [parentName, setParentName] = useState('')
    const { setSelectedStudentContext } = useLoginContext()
    const grades = ['Std XI', 'Std XII']
    const [selectedSubject, setSelectedSubject] = useState(grades[0])
    const [selectedStudent, setSelectedStudent] = useState('')
    const [students, setStudents] = useState([])

    const { studentDetails, loadingLeaderBoard,errors:leaderboardError } = useLeaderBoard()
    const { qBankUsage, performance, loadingGraph,errors:graphDetailError } = useGraphDetail(selectedStudent)
    const { todayQbankUsed, todayFlashcardUsed,errors:todayUsageError } = useTodayUsage(selectedStudent)
    const { loadingLinkStudent, linkStudentLoading ,errors:linkedStudentError} = useLinkedStudents(setParentName, setStudents, setSelectedStudent)
    const { allCourses, loadingAllCourses ,errors:allCoursesError} = useGetAllCourses()
    const filterCourses = (grade) => {
        return allCourses.filter((subject) => subject.name === grade)
    }
    const errors=[leaderboardError,graphDetailError,todayUsageError,linkedStudentError,allCoursesError]
    errors.forEach((err)=>{
        err.current&&toast.error(err.current)})
    useEffect(() => {
        if (selectedStudent !== '') {
            setSelectedStudentContext(students.filter((student) => student.id === selectedStudent.id)[0].id)
        }
    }, [selectedStudent])

    useEffect(() => {
        setSelectedSubject(grades[0])
    }, [])

    return (
        //highlightColor="#883F69"
        <SkeletonTheme highlightColor="#f9f9f9">
            <div className='mainHome'>
                <div className="header">
                    <div className="homeName">
                        <div className="helloName">Hello {!loadingLinkStudent ? parentName : <Skeleton width={10} />} </div>
                        <div className="homeCaption">Check Analytics of {!loadingLinkStudent ? selectedStudent.name.split(' ')[0] : <Skeleton width={5} />}</div>
                    </div>

                    <div className="homeLogo" onClick={() => { navigate('/student') }}>
                        {<img src={Logo} alt="Exampil" />}
                    </div>
                </div>

                <div className="studentTab">
                    {!linkStudentLoading ? students.map((student) => {
                        return <StudentToggle selectedStudent={selectedStudent} key={student.id} count={students.length} student={student} setSelectedStudent={setSelectedStudent} />
                    }) : <SkeletonStudentToggle />}
                </div>
                <div className="homeInnerContainer">
                    <div className="topStudents">
                        <div className="tabContent">
                            <div className="tabScroll">
                                {!loadingLeaderBoard ? studentDetails.map((item, id) => {
                                    return <LeaderBoardProfile key={id} {...item} />
                                }) : <SkeletonLeaderBoardProfile />}
                            </div>
                        </div>
                    </div>
                    <div className="subjectContainer">
                        <div className="subjectHeader">SubjectWise Analytics</div>
                        <div className="subjectContent">
                            <div className="subjectToggle">
                                {grades.map((grade, index) => {
                                    return <div className={`subjectGrade ${selectedSubject === grade ? 'selectedSubject' : ''}`} key={index} onClick={() => { setSelectedSubject(grade) }}>{grade}</div>
                                })}
                            </div>
                            <div className="subjectHolder">
                                {!loadingAllCourses ? filterCourses(selectedSubject).map((subject, index) => {
                                    return <SubjectIcon key={subject.id} selectedSubject={{ subjectName: subject.subjectName, name: subject.name }} allSubjects={allCourses} />
                                }) : <SkeltonSubjectIcon />}
                            </div>
                        </div>
                    </div>
                    {/* <div className="homeCardContainer">
                        <Notification />
                    </div> */}
                    <div className="overallPerformance">
                        <div className="statsHeader">Overall Performance</div>
                        {!loadingGraph ? <Stats style={{boxSizing:'border-box',boxShadow:'0 0 8px 0 rgba(0,0,0,0.3)'}} performance={performance} /> : <SkeltonStats />}
                    </div>
                    <div className="qBankUsage">
                        <div className="statsHeader">QBank Usage</div>
                        {!loadingGraph ? <QBank qBankUsage={qBankUsage} /> : <SkeletonQBank />}
                    </div>
                    <div className="doneToday">
                        <div className="statsHeader">What did {selectedStudent.name} do today?</div>
                        <Today todayQbankUsed={todayQbankUsed} todayFlashcardUsed={todayFlashcardUsed} />
                    </div>
                    <div className="calendar" id='calendar'>
                        <div className="statsHeader" style={{marginBottom:'0.5em'}}>Calendar</div>
                        <Calendar />
                    </div>
                </div>
                <div><Toaster position="top-center" reverseOrder={true} /></div>
            </div>
        </SkeletonTheme>
    )
}

export default Home