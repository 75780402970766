import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Login from './pages/Login';
import LinkEntity from './pages/Link/LinkEntity';
import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import Student from './pages/Student/Student';
import Register from './pages/Register/Register';
import ProtectedRoute from './auth/ProtectedRoute';
import ChapterAnalysis from './pages/ChapterAnalysis/ChapterAnalysis';

function App() {

  const router = createBrowserRouter([
    {
      path: "/login",
      element: <><Login /></>,
    },
    {
      path: "/register",
      element:<ProtectedRoute><Register /></ProtectedRoute>,
    },
    {
      path: "/link",
      element: <ProtectedRoute><LinkEntity /></ProtectedRoute>,
    },
    {
      path: "/",
      element: <ProtectedRoute><Home /></ProtectedRoute>,
    },
    {
      path: "/profile",
      element: <ProtectedRoute><Profile /></ProtectedRoute>,
    },
    {
      path: "/student",
      element: <ProtectedRoute><Student /></ProtectedRoute>,
    },
    {
      path: "/subject",
      element: <ProtectedRoute><ChapterAnalysis /></ProtectedRoute>,
    },
    {
      path: "*",
      element: <div>Page Not Found</div>,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
