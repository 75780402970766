
import axios from '../helper/axios'
import { getValidToken } from './auth';

let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
};

export const postData = async (url, data) => {
    const token = await getValidToken()
    if (token != null) {
        headersList = { ...headersList, 'Authorization': `Bearer ${token}` }
    }
    let bodyContent = JSON.stringify(data);
    try {
        let response = await axios.post(url, bodyContent, { headers: headersList });
        if (response.data.success!==0) {
            return response.data
        }
    } catch (err) {
        throw new Error (err.response.data.message)
    }
} 
export const getData = async (url) => {
    const token = await getValidToken()
    if (token != null) {
        headersList = { ...headersList, 'Authorization': `Bearer ${token}` }
    }
    try {
        let response = await axios.get(url, { headers: headersList });
        if (response.status === 200) {
            return response.data
        }
    } catch (err) {
        throw new Error (err.response.data.message)
    }

}
export const deleteData = async (url, data) => {
    const token = await getValidToken()
    if (token != null) {
        headersList = { ...headersList, 'Authorization': `Bearer ${token}` }
    }
    let bodyContent = JSON.stringify(data);
    try {
        let response = await axios.delete(url, { data: bodyContent, headers: headersList });
        if (response.status === 200) {
            return response.data
        }
        
    } catch (err) {
        throw new Error(err.response.data.message)
    }
}