import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../reducers/LoginReducer";

const LoginContext = createContext();
const initialState = {
    currentStudent:null,
    parentName:''
}

const LoginProvider = ({ children }) => {
    
    const [state, dispatch] = useReducer(reducer, initialState);
    const setSelectedStudentContext = (data) => {
        dispatch({ type: "SET_SELECTED_STUDENT", payload: data })
    }
    const setParent=(data)=>{
        dispatch({type:"SET_PARENT_NAME",payload:data})
    }
    
    return (
        <LoginContext.Provider value={{ ...state, dispatch, setSelectedStudentContext ,setParent}}>
            {children}
        </LoginContext.Provider>
    )
}


const useLoginContext = () => {
    return useContext(LoginContext);
}

export { LoginProvider, useLoginContext }