import './LeaderBoardProfile.css'
import React from 'react'
import Logo from '../../assets/images/ironManLogo.png'
import {FaUserCircle} from 'react-icons/fa'
const LeaderBoardProfile = ({rank,name,profileImg}) => { 
  const shrinkName=(name)=>{
    const firstName=name.split(' ')[0]
    const lastName=name.split(' ')[1]
    if(firstName.length>7){ 
      return firstName
    }
    else{
      return firstName+' '+lastName[0]+'.'
    }
  }
  return ( 
    <div className='profileMainContainer'>
      <div className="profileInnerContainer">
        <div className="imageContainer">
            {/* {<img src={Logo} alt="Image" />} */}
            {profileImg?<img src={profileImg} alt="" style={{maxWidth:'100%',borderRadius:'50%'}}/>:<FaUserCircle size='4em' color='#DBDBDB'/>}
        </div>
        <div className="stuRank">Rank #{rank}</div>
        <div className="stuName">{shrinkName(name)}</div>
      </div>
    </div>
  )
}

export default LeaderBoardProfile