import './Stats.css'
import React, { useEffect } from 'react'
import { BsFillCircleFill } from 'react-icons/bs'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label } from 'recharts';
import { getPerformanceData } from '../../helper/FilterPerformanceData';

const Stats = ({ performance ,style}) => {
  const [correct,incorrect,unAnswered]=getPerformanceData(performance)
  console.log(performance);
  const labelValue=(((correct)/(correct+incorrect+unAnswered))*100).toFixed(2)
  const data = [
    { name: 'Group A', value: correct },
    { name: 'Group B', value: incorrect },
    { name: 'Group C', value: unAnswered }
  ];
  const COLORS = ['#1ac9a0', '#ec6867', '#dbdbdb'];
  return (
    <>
    {(correct!==0||incorrect!==0||unAnswered!==0)?<div className='statsContainer' style={style}>
      <div className="graph">
        <PieChart width={125} height={125} >
          <Pie
            data={data}
            cx='50%'
            cy='50%'
            innerRadius={45}
            outerRadius={60}
            fill="#8884d8"
            cornerRadius={6}
            paddingAngle={1}
            dataKey="value"
            
          >
            <Label
              value={labelValue+'%'} position="center" className='labelColor' fontSize='25px' fontWeight={600} fill='#8C3562'
            />
            
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="labels">
        <div className="correct"><BsFillCircleFill />Correct({correct})</div>
        <div className="incorrect"><BsFillCircleFill />Incorrect({incorrect})</div>
        <div className="na"><BsFillCircleFill />UnAnswered({unAnswered})</div>
      </div>
    </div>:<div className='statsContainer'>No Data To Display</div>}
    </>
  )
}

export default Stats