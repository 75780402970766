import React from 'react'
import './PopupModal.css'
import { Box, Modal } from '@mui/material';
const PopupModal = ({ open, handleClose,confirmButton ,message}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '20%',
        bgcolor: 'background.paper',
        borderRadius: '0.5em',
        boxShadow: 24,
        p: 3,
        outline:'none',
        border:'none'    
      };
    return ( 
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="modalContainer">
                    <div className="gridModal">
                        <div className="areYouConfirm">
                        Are you sure you want to
                        </div>
                        <div className="message">
                            {message}?
                        </div>
                        <div className="confirmButton">
                            <button onClick={()=>{confirmButton();handleClose()}}>Confirm</button>
                        </div>
                        <div className="cancelButton">
                            <button onClick={handleClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default PopupModal