import './ChapterAnalysis.css'
import React, { useState ,useEffect} from 'react'
import Select from 'react-select';
import BackArrow from '../../assets/images/backArrow.png'
import { useNavigate } from 'react-router-dom'
import Accordion from '../../components/Accordion/Accordion';
import { getData } from '../../services/api';
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from 'react-router-dom'
const ChapterAnalysis = () => {
  const {state}=useLocation()
  const navigate = useNavigate()
  const {allSubjects,selectedSubject}=state
  const [selectedOption, setSelectedOption] = useState({value:selectedSubject.subjectName+' '+selectedSubject.name,
  label:selectedSubject.subjectName+' '+selectedSubject.name.split(' ')[1]});
  const [chapters, setChapters] = useState([])
  const [activeIndex, setActiveIndex] = useState(null);

  const options=allSubjects.map((subject)=>{return {value:subject.subjectName+' '+subject.name,label:subject.subjectName+' '+subject.name.split(' ')[1]}})
  
  useEffect(() => {
    setChapters(allSubjects.filter((subject)=>(subject.subjectName+' '+subject.name)===selectedOption.value)[0].chapter)
    setActiveIndex(null)
  },[selectedOption])
  return (
    <div className="chapterMainContainer">

      <div className="chapterHeader">
        <div className="backOptionAnalytics" onClick={() => { navigate(-1) }}><img src={BackArrow} alt="Back" />Analytics</div>
      </div>
      <div className="chapterDropdown">
        <Select className='selectProfile'
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options} placeholder='Subject' styles={customStyles}
          components={{ IndicatorSeparator: () => null }}
        />
      </div>
      <div className="chapterAccordion">
        <Accordion chapters={chapters} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      </div>
      <div><Toaster position="top-center" reverseOrder={true} /></div>
    </div>
  )
}

export default ChapterAnalysis


// const options = [
//   { value: 'physics', label: 'Physics' },
//   { value: 'chemsitry', label: 'Chemistry' },
//   { value: 'maths', label: 'Maths' },
// ];
const customStyles = {
  menu: base => ({
    ...base,
    marginTop: 0,
    color: 'white'
  }),
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#fff" : "#000",
    backgroundColor: state.isSelected ? "rgb(66, 33, 66)" : "#fff",
    height: "50px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.1em',
    msOverflowStyle:'none',
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",
    textAlign: "left",
    fontSize: '1.2em',
    width: '100%',

    color: 'white'
  }),
  // singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#111" }),
};

