import React from 'react'
import './StudentMenu.css'
import Skeleton from 'react-loading-skeleton'
import Analytics from '../../assets/images/pie-chart.png'
import Calendar from '../../assets/images/cal.png'
import Subs from '../../assets/images/subs.png'
import Refer from '../../assets/images/refer.png'
const SkeletonStudentMenu = () => {
    const textWidth=150
    const imgCircleWidth=40
    const circleIcon=35
    return (
        <>
            <div className='stuContainer'>
                <div className="stuHolder">
                    <div className="stuDet">
                        <div className="stuNo"><Skeleton width={50} /></div>
                        <div className="stuActual">
                            <div className="stuImage"><Skeleton circle  width={imgCircleWidth} height={imgCircleWidth}/></div>
                            <div className="stuDetails">
                                <div className="studentName"><Skeleton width={100}/></div>
                                <div className="studentClass"><Skeleton width={70}/></div>
                            </div>
                        </div>
                    </div>
                    <div className="analytics"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                    <div className="cal"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                    <div className="subs"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                    <div className="ref"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                </div>
            </div>
            <div className='stuContainer'>
                <div className="stuHolder">
                    <div className="stuDet">
                        <div className="stuNo"><Skeleton width={50} /></div>
                        <div className="stuActual">
                            <div className="stuImage"><Skeleton circle  width={imgCircleWidth} height={imgCircleWidth}/></div>
                            <div className="stuDetails">
                                <div className="studentName"><Skeleton width={100}/></div>
                                <div className="studentClass"><Skeleton width={70}/></div>
                            </div>
                        </div>
                    </div>
                    <div className="analytics"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                    <div className="cal"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                    <div className="subs"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                    <div className="ref"><div className="catLogo"><Skeleton circle width={circleIcon} height={circleIcon}/></div><div className='catContent'><Skeleton width={textWidth}/></div></div>
                </div>
            </div>
        </>
    )
}

export default SkeletonStudentMenu