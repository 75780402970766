import './StudentMenu.css'
import Analytics from '../../assets/images/pie-chart.png'
import Calendar from '../../assets/images/cal.png'
import Subs from '../../assets/images/subs.png'
import Refer from '../../assets/images/refer.png'
import React, { useRef } from 'react'
import { HashLink } from 'react-router-hash-link';
import officalLogo from '../../assets/images/officialLogo.png'
import logo from '../../assets/images/ironManLogo.png'
import { FaUserCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { getData } from '../../services/api'
import toast, { Toaster } from 'react-hot-toast'
import { useLoginContext } from '../../context/LoginDetailsContext'
const StudentMenu = ({ relationship, details, index }) => {
  const {setSelectedStudentContext}=useLoginContext()
  const studentName = details.name.split(' ').map((word) => word[0].toUpperCase() + word.slice(1)).join(' ')
  const navigate = useNavigate();
  function romanize(num) {
    if (isNaN(num))
      return NaN;
    var digits = String(+num).split(""),
      key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
        "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
        "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
      roman = "",
      i = 3;
    while (i--)
      roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }
  const getAllCourses = async () => {
    const courseApi = '/course'
    try {
      const response = await getData(courseApi)
      return response.data
    }
    catch (err) {
      toast.error(err.message)
    }
  }
  const processAndNavigate = async () => {
    const allSubjects = await getAllCourses()
    const selectedSubject = { subjectName: allSubjects[0].subjectName, name: allSubjects[0].name }
    console.log(selectedSubject);
    setSelectedStudentContext(details._id)
    navigate('/subject', { state: { allSubjects: allSubjects, selectedSubject: selectedSubject } })
  }
  return (
    <div className='stuContainer'>
      <div className="stuHolder">
        <div className="stuDet">
          <div className="stuNo">Student: {index + 1}</div>
          <div className="stuActual">
            <div className="stuImage" >
              <div className="stuLogo" >
                {details.profileImg ? <img src={details.profileImg} alt="" style={{ maxWidth: '100%', borderRadius: '50%' }} /> : <FaUserCircle size='4em' color='#DBDBDB' />}
              </div>
            </div>
            <div className="stuDetails">
              <div className="studentName">{studentName}</div>
              <div className="studentClass">Class {romanize(details.class)}</div>
            </div>
          </div>
        </div>
        <div className="analytics"
          onClick={processAndNavigate}
        ><div className="catLogo"><img src={Analytics} alt="" /></div><div className='catContent'>Analytics</div></div>


        <div className="cal">
          <div className="catLogo">
            <img src={Calendar} alt="" />
          </div>
          <HashLink to="/#calendar" className='catContent hashLink' >
            Calendar
          </HashLink>
        </div>


        <div className="subs"><div className="catLogo"><img src={Subs} alt="" /></div><div className='catContent'>Subscription</div></div>
        {/* <div className="ref"><div className="catLogo"><img src={Refer} alt="" /></div><div className='catContent'>Refer and Earn</div></div> */}
      </div>
      <div><Toaster position="top-center" reverseOrder={true} /></div>
    </div>
  )
}

export default StudentMenu