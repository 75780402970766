import React from 'react'  
import './StudentToggle.css' 
const StudentToggle = ({student,setSelectedStudent,selectedStudent,count}) => {
    const width=(100/count).toFixed(2)
    return ( 
        <div className='toggelMainConatiner' onClick={()=>{setSelectedStudent(student)}} style={selectedStudent.id===student.id?{borderBottom:'3px solid #883F69' , fontWeight:'1000',width:`${width}%`}:{backgroundColor:'#F1F1F1',color:'black',width:`${width}%`}}>
            <div className="toggelText" >{student.name}</div>
         </div>
    )
}

export default StudentToggle